@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide print-only elements when not printing */
.print-only {
  display: none;
}

/* Simple print styles */
@media print {
  /* Hide non-printable elements */
  .no-print {
    display: none !important;
  }
  
  /* Show print-only elements */
  .print-only {
    display: block !important;
    position: static;
  }
  
  /* Basic page setup */
  @page {
    size: A4;
    margin: 2cm;
  }
  
  /* Basic print styles */
  .print-section {
    break-inside: avoid;
    page-break-inside: avoid;
    margin-bottom: 20px;
  }

  .print-page-break {
    page-break-before: always;
  }

  .print-header {
    text-align: center;
    margin-bottom: 30px;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
  }

  .print-rating {
    border: 1px solid #000;
    padding: 15px;
    margin: 10px 0;
    page-break-inside: avoid;
  }

  .print-footer {
    text-align: center;
    font-size: 10pt;
    margin-top: 30px;
    padding-top: 10px;
    border-top: 1px solid #000;
  }

  /* Add these print-specific styles to your existing CSS */
  /* Hide everything by default */
  body * {
    visibility: visible !important;
    overflow: visible !important;
    height: auto !important;
  }

  /* Only show the printable version */
  .print-only,
  .print-only * {
    visibility: visible !important;
    overflow: visible !important;
    height: auto !important;
  }

  /* Reset any fixed dimensions */
  .print-only {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  /* Ensure proper page breaks */
  section {
    break-inside: avoid;
    page-break-inside: avoid;
  }

  /* Hide the no-print elements */
  .no-print {
    display: none !important;
  }

  /* Ensure text is black for better printing */
  body {
    color: black;
  }

  /* Ensure links don't show their URL */
  a[href]:after {
    content: none !important;
  }

  /* Adjust font sizes for print */
  h1 {
    font-size: 24pt;
  }

  h2 {
    font-size: 20pt;
  }

  h3 {
    font-size: 16pt;
  }

  p, li {
    font-size: 12pt;
    line-height: 1.5;
  }

  /* Remove any max-height constraints */
  .p-8 {
    max-height: none !important;
  }

  /* Ensure grid items don't get cut off */
  .grid {
    display: block !important;
  }

  .grid > * {
    margin-bottom: 1rem;
  }
}

